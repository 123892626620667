




















































































































import { AxResponse } from '@/core/interfaces';
import{ Component, Prop, Vue }from 'vue-property-decorator';

@Component
export default class extends Vue{

  @Prop() id!: string;

  @Prop() type!: string;

  private loading = true;
  private yaerAll = false;
  private gradesAll = false;
  private semesterAll = false;
  private courseTypesAll = false;
  private bookVersionsAll = false;

  private titles = { 
    primary: { title: '小学', img: require('@/assets/customer/auth-primary.png')}, 
    middle: { title: '初中', img: require('@/assets/customer/auth-middle.png')}, 
    high: { title: '高中', img: require('@/assets/customer/auth-high.png')}, 
  };
  
  /**
   * @tip 循环的数组
   * @oldTip 禁用  - 已有的数组
   * @modelAll 全选按钮 - 渲染的数组
   */
  private checkChangeAll(tip, oldTip, modelAll) {
    console.log(this.currentSubject, 'tip, oldTip, modelAll', tip, oldTip, modelAll )
    this.currentSubject[tip] = this.currentSubject[tip].map(i => {
      if(this[modelAll]) {
        i.check = true
      }
      else if(this.currentSubject[oldTip]) {
        i.check = this.currentSubject[oldTip].includes(i.id)
      }
      else i.check = this[modelAll]
      return i
    })
  }
  /**
   * 默认加载  判断全选是否不可更改
   */
  private checkAllHandle() {
    let subject = this.currentSubject
    if(subject.groupGrades && subject.grades) this.gradesAll = subject.groupGrades.length === subject.grades.length ? true : false
    if(subject.groupYears && subject.years) this.yaerAll = subject.groupYears.length === subject.years.length ? true : false
    if(subject.groupSemester && subject.semester) this.semesterAll = subject.groupSemester.length === subject.semester.length ? true : false
    if(subject.groupCourseTypes && subject.courseTypes) this.courseTypesAll = subject.groupCourseTypes.length === subject.courseTypes.length ? true : false
    if(subject.groupBookVersions && subject.bookVersions) this.bookVersionsAll = subject.groupBookVersions.length === subject.bookVersions.length ? true : false

    if(subject.years) this.yaerAll = subject.years.every(i => i.check)
    if(subject.grades) this.gradesAll = subject.grades.every(i => i.check)
    if(subject.semester) this.semesterAll = subject.semester.every(i => i.check)
    if(subject.courseTypes) this.courseTypesAll = subject.courseTypes.every(i => i.check)
    if(subject.bookVersions) this.bookVersionsAll = subject.bookVersions.every(i => i.check)
  }
  private gradeRanges: any[] = [];
  async created() {
    let url = this.type === 'user' ? '/permission/user/queryUserDataPermission' : '/admin/group/queryGroupDetailByGroupId';
    let params = this.type === 'user' ? { userId: this.id } : { groupId: this.id };
    const res: AxResponse = await this.axios.get(url, { params });
    if (res && res.result) {
      if (this.type === 'user') {
        res.json.gradeRanges = JSON.parse(JSON.stringify(res.json).replaceAll(/userSubjectBeans/g, 'groupSubjects')).map(g => {g.groupSubjects.map(i => {
          i.years && i.years.map(y => {
            let checked = i.groupYears && i.groupYears.includes(y.id) || i.userYears && i.userYears.includes(y.id);
            y.check = checked;
            return y;
          });

          i.grades && i.grades.map(y => {
            let checked = i.groupGrades && i.groupGrades.includes(y.id) || i.userGrades && i.userGrades.includes(y.id);
            y.check = checked;
            return y;
          })

          i.semester && i.semester.map(y => {
            let checked = i.groupSemester && i.groupSemester.includes(y.id) || i.userSemester && i.userSemester.includes(y.id);
            y.check = checked;
            return y;
          })

          i.bookVersions && i.bookVersions.map(y => {
            let checked = i.groupBookVersions && i.groupBookVersions.includes(y.id) || i.userBookVersions && i.userBookVersions.includes(y.id);
            y.check = checked;
            return y;
          })

          i.courseTypes && i.courseTypes.map(y => {
            let checked = i.groupCourseTypes && i.groupCourseTypes.includes(y.id) || i.userCourseTypes && i.userCourseTypes.includes(y.id);
            y.check = checked;
            return y;
          })
          return i;
        }); return g});
      }

      this.gradeRanges = res.json.gradeRanges;
      this.activeKey = { section: this.gradeRanges[0].gradeRangeCode, subject: this.gradeRanges[0].groupSubjects[0].subjectCode }
      this.getSubjectCheckNumber();
      await this.$nextTick();
      this.loading = false;
    }
  }

  private activeKey = { section: null, subject: null };
  private get currentSubject() {
    try {
      return this.gradeRanges.find(g => g.gradeRangeCode === this.activeKey.section).groupSubjects.find(i => i.subjectCode === this.activeKey.subject); 
    } catch (error) {
      return null;
    }
  }


  private curriculumTotalNumber = 0;
  // private get curriculumTotalNumber() { 
  //   let n = 0;
  //   this.gradeRanges.map(g => g.groupSubjects.map(subject => {
  //     subject.years && subject.years.length && subject.years.map(s => s.check && n++)
  //     subject.grades && subject.grades.length && subject.grades.map(s => s.check && n++)
  //     subject.semester && subject.semester.length && subject.semester.map(s => s.check && n++)
  //     subject.bookVersions && subject.bookVersions.length && subject.bookVersions.map(s => s.check && n++)
  //     subject.courseTypes && subject.courseTypes.length && subject.courseTypes.map(s => s.check && n++)
  //   }))
  //   return n; 
  // }

  private async getSubjectCheckNumber(isGetCourseCount = true) {
    this.gradeRanges.map(g => g.groupSubjects.map(i => {
      let n = 0;
      i.years && i.years.map(s => s.check && n++)
      i.grades && i.grades.map(s => s.check && n++)
      i.semester && i.semester.map(s => s.check && n++)
      i.bookVersions && i.bookVersions.map(s => s.check && n++)
      i.courseTypes && i.courseTypes.map(s => s.check && n++)
      i.checkedNum = n;
      return i;
    }));

    if (isGetCourseCount) {
      let params: any = { subjectId: this.currentSubject.subjectCode };
      this.currentSubject.years && (params.years = this.currentSubject.years.filter(i => i.check).map(i => i.id).join(','))
      this.currentSubject.grades && (params.gradeIds = this.currentSubject.grades.filter(i => i.check).map(i => i.id).join(','))
      this.currentSubject.semester && (params.semesterIds = this.currentSubject.semester.filter(i => i.check).map(i => i.id).join(','))
      this.currentSubject.courseTypes && (params.courseTypeIds = this.currentSubject.courseTypes.filter(i => i.check).map(i => i.id).join(','));
      const res: AxResponse = await this.axios.get(`/course/queryCourseCount`, { params });
      this.curriculumTotalNumber = res.json || 0;
    }
    this.checkAllHandle()
  }

  async save() {
    let groupRules: any[] = [];
    this.gradeRanges.map(g => g.groupSubjects.map(i => {
      let groupRule: any = { subjectCode: i.subjectCode }
      let isPush = false;
      i.years && i.years.map(s => { s.check && (groupRule.year ? groupRule.year.push(s.id) : (groupRule.year = [s.id])) })
      i.grades && i.grades.map(s => { s.check && (groupRule.gradeId ? groupRule.gradeId.push(s.id) : (groupRule.gradeId = [s.id])) })
      i.semester && i.semester.map(s => { s.check && (groupRule.termId ? groupRule.termId.push(s.id) : (groupRule.termId = [s.id])) })
      i.bookVersions && i.bookVersions.map(s => { s.check && (groupRule.bookVersionId ? groupRule.bookVersionId.push(s.id) : (groupRule.bookVersionId = [s.id])) })
      i.courseTypes && i.courseTypes.map(s => { s.check && (groupRule.courseTypeId ? groupRule.courseTypeId.push(s.id) : (groupRule.courseTypeId = [s.id])) })
      groupRule.year && (groupRule.year = groupRule.year.join(','), isPush = true);
      groupRule.gradeId && (groupRule.gradeId = groupRule.gradeId.join(','), isPush = true);
      groupRule.termId && (groupRule.termId = groupRule.termId.join(','), isPush = true);
      groupRule.bookVersionId && (groupRule.bookVersionId = groupRule.bookVersionId.join(','), isPush = true);
      groupRule.courseTypeId && (groupRule.courseTypeId = groupRule.courseTypeId.join(','), isPush = true);
      isPush && groupRules.push(groupRule);
    }));
    this.$emit('loading', true);
    let url = this.type === 'user' ? '/permission/user/saveUserDataPermission' : '/admin/group/configGroupRule';
    let params = this.type === 'user' ? { userId: this.id, datas: groupRules } : { id: this.id, groupRules };
    const res: AxResponse = await this.axios.post(url, params, {
      headers: { 'Content-Type': 'application/json' }
    });
    this.$emit('loading')
    if (res && res.result) {
      this.$message.success('修改数据权限成功');
      this.$emit('close', true);
    } else {
      this.$message.warning(res.msg);
    }
  }

}
