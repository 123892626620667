






























import { AxResponse } from '@/core/interfaces';
import{ Component, Prop, Vue }from 'vue-property-decorator';
import TeacherGroupAuth from './teacher-group-auth.vue';
const pinyin = require('@/utils/pinyin');

@Component({
  name: 'teacher-menu'
})
export default class extends Vue{

  @Prop() teacherTotal!: number;

  private groupList: any[] = [];
  private groupActivedId = '';

  private notGroupedNum = 0;

  async getGroupList() {
    const res: AxResponse = await this.axios.post('/admin/group/query');
    if (res && res.result) {
      this.groupList = res.json.map(i => { i.pinyin = pinyin.convertToPinyin(i.name); return i; } );
      this.$emit('group-all-list', this.groupList);
    }
  }

  private searchValue = '';
  get groupListFilter() {
    if (this.searchValue) {
      return this.groupList.filter(g => g.name.includes(this.searchValue) || g.pinyin.includes(this.searchValue.toLocaleUpperCase()));
    }
    return this.groupList;
  }

  created() {
    this.getGroupList();
    this.getNotGroupNumber();
  }

  public getNotGroupNumber() {
    this.axios.get('/permission/user/queryPage?groupId=-1&size=1&current=1').then((res: any) => this.notGroupedNum = res.total)
  }

  private handleCommand(type: 'delete' | 'edit' | 'setting', data) {
    new Map<string, any>([
      ['delete', async () => {
        let confirm = await this.$confirm('此操作将永久删除该分组, 是否继续?', '提示', { type: 'warning' });
        if (confirm) {
          let res: AxResponse = await this.axios.post(`/admin/group/deleteById/${data.id}`);
          res && res.result && this.groupList.splice(this.groupList.findIndex(g => g.id === data.id), 1);
        }
      }],
      ['edit', this.update],
      ['setting', () => {
        this.$modal.create({
          title: '数据权限设置',
          width: 740  ,
          component: TeacherGroupAuth,
          props: { id: data.id, type: 'group' },
          headerStyle: { marginBottom: 0 },
          bodyStyle: { padding: 0 }
        })
      }]
    ]).get(type)(data);
  }

  private async update(data: any = {}) {
    if (data.id) {
      const res: AxResponse = await this.axios.get('/admin/group/queryGroupById', {params: { groupId: data.id }});
      res.json.groupSubjects = res.json.groupSubjects.map(i => i.subjectCode);
      data = res.json;
    }
    this.$modal.create({
      title: `${ data.id ? '编辑' : '新增' }组信息`,
      width: 400,
      component: 'form',
      props: {
        nodes: [
          { key: 'id' },
          { label: '分组名称', key: 'name', type: 'input' },
          { label: '学科', key: 'groupSubjects', multiple: true, type: 'select', valueKey: 'code', url: '/system/dictionary/queryAll?typeCode=SUBJECT', placeholder: '请选择学科（可多选）' },
        ],
        rules: {
          name: { required: true, message: '请输入分组名称' },
          groupSubjects: { required: true, validator: (r, v, c) => { c(v && v.length ? undefined : new Error('请选择学科')); } }
        },
        data
      }
    }).$on('close', async res => {
      if (res) {
        res.groupSubjects = res.groupSubjects.map(o => { return {subjectCode: o} });
        let response: AxResponse = await this.axios.post('/admin/group/saveOrUpdate', { ...res }, {
          headers: { 'Content-Type': 'application/json' }
        });
        response && response.result && this.$message.success(`${ data.id ? '编辑' : '新增' }分组成功`) && this.getGroupList();
      }
    })
  }


}
