
















import{ Component, Prop, Vue }from 'vue-property-decorator';

@Component
export default class extends Vue{
  @Prop() groupingLoading!: boolean;

  @Prop() groupList!: any[];

  private show = false;
  save() {
    this.show = false;
    this.$emit('save', this.groupList.filter(i => i.check).map(i => i.id));
  }
}
