















































import CusTable from '@/components/table';
import { AxResponse } from '@/core/interfaces';
import{ Component, Vue }from 'vue-property-decorator';
import Grouping from './components/grouping.vue';
import TeacherMenu from './components/teacher-menu.vue';
import TeacherGroupAuth from './components/teacher-group-auth.vue';

@Component({
  components: { TeacherMenu, Grouping }
})
export default class extends Vue{

  private nodes = [
    { label: '校区名称', key: 'schoolId', type: 'select', url: '/admin/school/query', labelKey: 'schoolName', valueKey: 'id', filterable: true },
    { label: '角色名称', key: 'roleId', type: 'select', url: '/permission/role/queryAll' },
    { label: '姓名/手机号', key: 'usernameOrMobile', type: 'input' },
    { label: '在线情况', key: 'online', type: 'select', options: [{ id: 0, name: '离线' }, { id: 1, name: '在线' }] },
  ];

  private groupingLoading = false;
  private showGrouping = false;
  private groupAllList: any[] = [];
  private systemName = process.env.VUE_APP_SYSTEM_NAME;

  private async grouping(user) {
    this.groupingLoading = true;
    const res: AxResponse = await this.axios.get(`/permission/user/queryUserGroupByUserId?userId=${user.id}`);
    this.groupingLoading = false;
    this.groupList = res.json;
  }

  private teacherTotal = 0;
  private groupList: any[] = [];

  $refs!: { table: CusTable, groupMenu: TeacherMenu };
  private tableDefault = { groupId: null };
  private groupChange(e) {
    this.tableDefault.groupId = e;
    this.$refs.table.request(this.tableDefault);
  }

  private handleCommand(type: 'delete' | 'reset' | 'state' | 'modfiy', data) {
    new Map<string, any>([
      ['modfiy', () => {
        this.preview(data)
      }],
      ['delete', async () => {
        let confirm = await this.$confirm('此操作将永久删除该教师, 是否继续?', '提示', { type: 'warning' });
        if (confirm) {
          let res: AxResponse = await this.axios.post(`/permission/user/deleteById/${data.id}`);
          if (res && res.result) {
            this.$refs.table.request()
            this.$refs.groupMenu.getGroupList();
            this.teacherTotal--;
            this.$message.success('删除教师成功')
          } else {
            this.$message.warning(res.msg);
          }
        }
      }],
      ['reset', async () => {
        let confirm = await this.$confirm('确认重置该教师密码吗?', '提示', { type: 'warning' });
        if (confirm) {
          let res: AxResponse = await this.axios.get(`/permission/user/resetPassword`, { params: { userId: data.id } });
          this.$message({ message: res.msg, type: res.result ? 'success' : 'warning'})
        }
      }],
      ['state', async () => {
        let res: AxResponse = await this.axios.get(`/permission/user/openOrClose`, { params: { userId: data.id, status: Number(!data.status) } });
        this.$message({ message: `编辑教师状态${res.result ? '成功' : '失败'}`, type: res.result ? 'success' : 'warning'});
        res.result && this.$refs.table.request();
      }]
    ]).get(type)(data);
  }

  private async groupingSave(groupIds, userId) {
    const res: AxResponse = await this.axios.post(`/permission/user/batchSaveOrRemoveUserGroup`, { userId, groupIds }, {
      headers: { 'Content-Type': 'application/json' }
    } );
    this.$message({ message: res.result ? '修改分组成功' : res.msg, type: res.result ? 'success' : 'warning' });
    this.$refs.table.request();
    this.$refs.groupMenu.getGroupList();
    this.$refs.groupMenu.getNotGroupNumber();
  }

  private authSetting(data) {
    this.$modal.create({
      title: '数据权限设置',
      width: 740  ,
      component: TeacherGroupAuth,
      props: { id: data.id, type: 'user' },
      headerStyle: { marginBottom: 0 },
      bodyStyle: { padding: 0 }
    })
  }

  private async preview(data: any = {}) {
    if (data.id) {
      const res: AxResponse = await this.axios.get(`/permission/user/queryUserDetailById?userId=${ data.id }`);
      res.json.roleIds = (res.json.userRoles || []).map(i => i.roleId);
      res.json.schoolIds = (res.json.userSchools || []).map(i => i.schoolId);
      res.json.groupIds = (res.json.userGroups || []).map(i => i.groupId);
      data = res.json;
    };
    this.$modal.create({
      title: `${data.id ? '修改' : '新增'}教师`,
      width: 400,
      component: 'form',
      props: {
        nodes: [
          { key: 'id' },
          { label: '教师姓名', key: 'nickname', type: 'input', disabled: this.systemName === 'sike' ? true : false },
          { label: '手机号', key: 'mobile', type: 'input', tip: '手机号修改后密码也将重置成修改后的手机号', disabled: this.systemName === 'sike' ? true : false },
          { label: '所属校区', key: 'schoolIds', type: 'select', multiple: true, url: '/admin/school/query', labelKey: 'schoolName' },
          { label: '角色', key: 'roleIds', type: 'select', multiple: true, url: '/permission/role/queryAll' },
          { label: '学科组', key: 'groupIds', type: 'select', multiple: true, options: this.groupAllList },
        ],
        rules: {
          nickname: { required: true, message: '请输入教师姓名' },
          mobile: { required: true, validator: (r, v, c) => {
            c( !/^1([9358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/.test(v) ? new Error('请输入正确的手机号') : undefined )
          }, message: '请输入正确的手机号' },
          roleIds: { required: true, message: '请选择所属角色' },
          groupIds: { required: true, message: '请选择所属学科' },
        },
        data
      }
    }).$on('close', async formGroup => {
      const res: AxResponse = await this.axios.post(`/permission/user/${ formGroup.id ? 'modify' : 'add' }`, formGroup, { headers: { 'Content-Type': 'application/json' } });
      if (res.result) {
        this.$refs.table.request();
        this.$message.success(`${data.id ? '修改' : '新增'}教师成功`);
      } else {
        this.$message.warning(res.msg);
      }
    });
  }
}
